/*
Table des matières :

KNACSS
Général
 |_ Titres
 |_ Tableaux
 |_ Accordéon
 |_ En savoir plus
 |_ Galerie
 |_ Icône produit
 |_ Onglets
 |_ Slider
     |_ Actualités
 |_ Divers
Regroupement
En-tête
 |_ Logo en-tête
 |_ Menu
     |_ Langues
     |_ Commandes
Recherche
Contenu principal
 |_ Slider
     |_ Slogan
     |_ Fabrication FR
 |_ Gamme
 |_ Sélection
 |_ Focus
     |_ Focus documentations
     |_ Focus actualités
 |_ Introduction
     |_ Fil d'ariane
 |_ Actualités
 |_ Contenu
     |_ Accueil
     |_ Société
     |_ Documentations
     |_ Blog
     |_ Contact
     |_ Erreur
     |_ WooCommerce
Arguments
Pied de page
 |_ Marque
 |_ Menu secondaire
 |_ Présentation rapide
 |_ Contact rapide
Liste produits
 |_ Tri
 |_ Filtres cat
 |_ Filtres sous-cat
 |_ Pagination
Fiche produit
 |_ Date MAJ produit
 |_ Documentations produit
 |_ Détails produit
 |_ Navigation produit
Media Queries
 |_ Tiny
 |_ Small
 |_ Medium
 |_ Large
 |_ 1360px
 |_ 1680px
*/

@font-face {
  font-family: 'helvetica_neue_lt_std_light';
  font-style: normal;
  font-weight: normal;
  src: url('../font/helvetica_neue_lt_std/helvetica_neue_lt_std_light/helvetica_neue_lt_std_light.eot');
  src: url('../font/helvetica_neue_lt_std/helvetica_neue_lt_std_light/helvetica_neue_lt_std_light.eot?#iefix') format('embedded-opentype'), url('../font/helvetica_neue_lt_std/helvetica_neue_lt_std_light/helvetica_neue_lt_std_light.woff2') format('woff2'), url('../font/helvetica_neue_lt_std/helvetica_neue_lt_std_light/helvetica_neue_lt_std_light.woff') format('woff'), url('../font/helvetica_neue_lt_std/helvetica_neue_lt_std_light/helvetica_neue_lt_std_light.ttf') format('truetype'), url('../font/helvetica_neue_lt_std/helvetica_neue_lt_std_light/helvetica_neue_lt_std_light.svg#helvetica_neue_lt_std_light') format('svg');
}

@font-face {
  font-family: 'helvetica_neue_lt_std_roman';
  font-style: normal;
  font-weight: normal;
  src: url('../font/helvetica_neue_lt_std/helvetica_neue_lt_std_roman/helvetica_neue_lt_std_roman.eot');
  src: url('../font/helvetica_neue_lt_std/helvetica_neue_lt_std_roman/helvetica_neue_lt_std_roman.eot?#iefix') format('embedded-opentype'), url('../font/helvetica_neue_lt_std/helvetica_neue_lt_std_roman/helvetica_neue_lt_std_roman.woff2') format('woff2'), url('../font/helvetica_neue_lt_std/helvetica_neue_lt_std_roman/helvetica_neue_lt_std_roman.woff') format('woff'), url('../font/helvetica_neue_lt_std/helvetica_neue_lt_std_roman/helvetica_neue_lt_std_roman.ttf') format('truetype'), url('../font/helvetica_neue_lt_std/helvetica_neue_lt_std_roman/helvetica_neue_lt_std_roman.svg#helvetica_neue_lt_std_roman') format('svg');
}

@font-face {
  font-family: 'helvetica_neue_lt_std_medium';
  font-style: normal;
  font-weight: normal;
  src: url('../font/helvetica_neue_lt_std/helvetica_neue_lt_std_medium/helvetica_neue_lt_std_medium.eot');
  src: url('../font/helvetica_neue_lt_std/helvetica_neue_lt_std_medium/helvetica_neue_lt_std_medium.eot?#iefix') format('embedded-opentype'), url('../font/helvetica_neue_lt_std/helvetica_neue_lt_std_medium/helvetica_neue_lt_std_medium.woff2') format('woff2'), url('../font/helvetica_neue_lt_std/helvetica_neue_lt_std_medium/helvetica_neue_lt_std_medium.woff') format('woff'), url('../font/helvetica_neue_lt_std/helvetica_neue_lt_std_medium/helvetica_neue_lt_std_medium.ttf') format('truetype'), url('../font/helvetica_neue_lt_std/helvetica_neue_lt_std_medium/helvetica_neue_lt_std_medium.svg#helvetica_neue_lt_std_medium') format('svg');
}

/* ------------------------------- */

/*  =     KNACSS                   */

/* ------------------------------- */

:root .grid > * > * {
  font-size: 16px;
  font-size: 1.6rem;
}

/* ------------------------------- */

/*  =     Général                  */

/* ------------------------------- */

body {
  color: #666;
  font-family: 'helvetica_neue_lt_std_light', 'helvetica neue', helvetica, arial, sans-serif;
  font-size: 1.6em; /* 16px / 10px */
}

a:link,
a:visited {
  outline: 1px dotted transparent;
  text-decoration: none;
  transition: all 0.5s;
}

button,
input,
textarea,
select,
a.btn {
  border: 1px solid #ccc;
  outline-style: none;
  padding: 0 25px;
  transition-duration: 0.5s;
}

button,
input,
select,
a.btn {
  line-height: 3.125; /* 50px / 16px */
}

input,
select {
  height: 3.125em;
}

input,
textarea,
select {
  border-left-width: 10px;
  color: #000;
  transition-property: border-left-width;
}

input[type='checkbox'],
input[type='radio'],
input[type='file'] {
  border-style: hidden;
}

input[type='checkbox'],
input[type='radio'] {
  padding: 0;
}

button,
input[type='button'],
input[type='reset'],
input[type='submit'],
a.btn {
  background-color: #fff !important;
  border-left-width: 1px !important;
  color: #333 !important;
  font-family: 'helvetica_neue_lt_std_medium', 'helvetica neue', helvetica, arial, sans-serif;
  height: auto;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
}

button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  transition-property: border-color, color;
}

input:focus,
textarea:focus,
select:focus {
  border-left-width: 20px;
}

button:hover,
button:focus,
input[type='button']:hover,
input[type='button']:focus,
input[type='reset']:hover,
input[type='reset']:focus,
input[type='submit']:hover,
input[type='submit']:focus,
a.btn:hover,
a.btn:focus {
  border-color: #d31f31;
  color: #d31f31 !important;
}

a.btn.btn_principal,
button.btn_principal,
input.btn_principal {
  background-color: #d31f31 !important;
  border-color: transparent;
  color: #fff !important;
}

a.btn.btn_principal:hover,
a.btn.btn_principal:focus,
button.btn_principal:hover,
button.btn_principal:focus,
input.btn_principal:hover,
input.btn_principal:focus {
  background-color: #333 !important;
}

/*  = --- Titres ----------------- */

h1,
.h1-like,
h2,
.h2-like,
h3,
.h3-like,
h4,
.h4-like,
h5,
.h5-like,
h6,
.h6-like {
  font-weight: 400;
  line-height: 1;
}

h1,
.h1-like,
h2,
.h2-like,
h3,
.h3-like {
  color: #333;
}

h1,
.h1-like,
h2,
.h2-like,
h5,
.h5-like,
h6,
.h6-like {
  text-transform: none;
}

h1::after,
.h1-like::after,
h2::after,
.h2-like::after {
  background-color: #d31f31;
  content: '';
  display: block;
  height: 2px;
  width: 70px;
}

h1.txtcenter::after,
.h1-like.txtcenter::after,
h2.txtcenter::after,
.h2-like.txtcenter::after {
  margin-left: auto;
  margin-right: auto;
}

h1.txtright::after,
.h1-like.txtright::after,
h2.txtright::after,
.h2-like.txtright::after {
  margin-left: 0;
}

h1,
.h1-like {
  font-size: 2.5em; /* 40px / 16px */
}

h1::after,
.h1-like::after {
  margin-top: 0.75em; /* 30px / 40px */
}

h2,
.h2-like {
  font-size: 1.875em; /* 30px / 16px */
}

h2::after,
.h2-like::after {
  margin-top: 1em;
}

h3,
.h3-like,
h4,
.h4-like {
  text-transform: uppercase;
}

h3,
.h3-like {
  font-size: 1.125em; /* 18px / 16px */
}

h4,
.h4-like,
h5,
.h5-like,
h6,
.h6-like {
  color: #d31f31;
  font-size: 0.875em; /* 14px / 16px */
}

.h3-like::after,
.h4-like::after,
.h5-like::after,
.h6-like::after {
  display: none;
}

h1 > a,
.h1-like > a,
h2 > a,
.h2-like > a,
h3 > a,
.h3-like > a,
h4 > a,
.h4-like > a,
h5 > a,
.h5-like > a,
h6 > a,
.h6-like > a {
  background-color: transparent !important;
  color: inherit !important;
  outline-style: none !important;
}

/*  = --- Tableaux --------------- */

.table {
  border-style: hidden;
}

.table a {
  background-color: transparent !important;
  color: inherit !important;
  transition-property: none;
}

.table a:hover,
.table a:focus {
  color: #d31f31 !important;
}

.table > caption,
.table th,
.table td {
  border-style: none none solid !important;
}

.table > caption {
  font-style: inherit;
  margin-top: 0;
  padding: 0;
}

.table tr {
  transition: background-color 0.25s;
}

.table th,
.table td {
  padding: 0.9375em 1.25em; /* [15px / 16px] et [20px / 16px] */
}

.table th {
  font-weight: normal;
}

.table th > a {
  color: #333 !important;
}

.table td > a:hover,
.table td > a:focus {
  font-weight: 700;
}

/*  = --- Accordéon -------------- */

.accordion .accordion-title {
  border-style: none none solid;
  cursor: pointer;
  outline-style: none;
  transition: border-color 0.5s;
}

.accordion-title::before {
  color: #666;
  content: '\f054';
  float: right;
  font-family: fontawesome;
  transition: transform 0.5s;
}

.accordion-title.open {
  border-bottom-color: transparent;
}

.accordion-title.open::before {
  transform: rotate(90deg);
}

h2.accordion-title::after {
  transition: width 0.5s;
}

h2.accordion-title:not(.open)::after {
  width: 0;
}

/*  = --- En savoir plus --------- */

.en_savoir_plus {
  position: relative;
}

.en_savoir_plus::before,
.en_savoir_plus::after {
  bottom: 0;
  position: absolute;
  right: 0;
}

.en_savoir_plus::before {
  border: 0 solid #d31f31;
  border-left-color: transparent;
  border-top-color: transparent;
  content: '';
  transition: border-width 0.5s;
}

.en_savoir_plus::after {
  color: #d31f31;
  content: '\f054';
  font-family: fontawesome;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  transition: color 0.5s;
  width: 40px;
}

.en_savoir_plus:hover::before,
a.en_savoir_plus:focus::before {
  border-width: 40px; /* 80px / 2 */
}

.en_savoir_plus:hover::after,
a.en_savoir_plus:focus::after {
  color: #fff;
}

/*  = --- Galerie ---------------- */

.galerie {
  margin: 20px auto;
}

.galerie > img {
  margin: 0;
  padding: 0.56179775280898876404494382022472%; /* 5px * 100 / 890px */
}

/*  = --- Icône produit ---------- */

.icone_produit {
  position: relative;
}

.icone_produit::before {
  background-image: url('../img/icone_produit.png');
  background-repeat: no-repeat;
  content: '';
  display: none;
  height: 100px;
  left: 0;
  position: absolute;
  width: 100px;
}

.application_manuelle::before {
  background-position: center top;
}

.traitement_joints::before {
  background-position: center -100px;
}

.pulverisation_tyrex::before {
  background-position: center -200px;
}

.projection_traditionnelle::before {
  background-position: center -300px;
}

.decoration::before {
  background-position: center -400px;
}

.pulverisation_airless::before {
  background-position: center -500px;
}

.autres_produits::before {
  background-position: center -600px;
}

/*  = --- Onglets ---------------- */

.onglets,
.onglets a {
  border-width: 2px !important;
}

.onglets,
.onglets a:hover,
.onglets a:focus,
.onglets .current-cat > a {
  border-color: #999 !important;
}

.onglets a:hover,
.onglets a:focus,
.onglets .current-cat > a {
  color: #d31f31 !important;
}

.onglets {
  border-style: hidden !important;
  font-size: 0.75em; /* 12px / 16px */
  text-transform: uppercase;
}

.onglets > ul {
  padding-left: 0;
}

.onglets li + li {
  margin-top: 2px;
}

.onglets a {
  background-color: transparent !important;
  color: inherit !important;
  display: block;
  padding: 10px;
}

/*  = --- Slider ----------------- */

.slider {
  background: #383e44 url('../img/chargement.gif') no-repeat center;
  overflow: hidden;
}

.slider div.flexslider {
  margin-bottom: 0;
}

.slider div.caption-wrap {
  background-color: transparent;
  opacity: 1;
  top: 0;
}

.slider div.caption {
  display: table;
  height: 100%;
  padding: 0;
  width: 100%;
}

.slider .caption > div {
  display: table-cell;
  padding: 20px;
  vertical-align: middle;
}

.slider div.caption img {
  display: inline-block;
}

.slider .caption .btn {
  background-color: transparent !important;
  border-width: 2px !important;
}

.slider .caption .btn > i {
  color: #d31f31;
}

.slider .flex-control-nav {
  bottom: 30px;
  padding: 0 !important;
  z-index: 2;
}

.slider .flex-control-nav > li {
  margin: 0;
}

.slider .flex-control-paging a {
  background-color: transparent;
  border: 2px solid #666;
  border-radius: 50%;
  box-shadow: none;
  height: 15px;
  margin: 0 5px; /* 10px / 2 */
  width: 15px;
}

.slider .flex-control-paging a:hover,
.slider .flex-control-paging a:focus,
.slider .flex-control-paging a.flex-active {
  background-color: #666;
}

/* Actualités */

.actualites .ms-postfeed > a {
  background-color: #d31f31;
  display: block;
  overflow: hidden;
}

.actualites .ms-postfeed > a > img {
  transition: all 0.5s;
}

.actualites .ms-postfeed > a:hover > img,
.actualites .ms-postfeed > a:focus > img {
  filter: alpha(opacity=50);
  opacity: 0.5;
  transform: scale(1.25);
}

.actualites .caption {
  padding: 25px 0 !important;
}

.actualites dt {
  text-transform: none;
}

.actualites .caption dd:last-child {
  background-color: #333;
  color: #fff;
}

.actualites .caption dd:last-child {
  bottom: 100%;
  min-width: 40%;
  position: absolute;
}

.actualites .caption dd:last-child > a:hover,
.actualites .caption dd:last-child > a:focus {
  color: #999 !important;
}

/*  = --- Divers ----------------- */

.invisible {
  display: none !important;
}

.mw1360p {
  max-width: 1360px;
}

.mw1680p {
  max-width: 1680px;
}

ul.liste_puces {
  list-style-type: none;
  padding-left: 0;
}

ul.liste_puces > li::before {
  color: #d31f31;
  content: '\f054';
  font-family: fontawesome;
  margin-right: 5px;
  vertical-align: middle;
}

/* ------------------------------- */

/*  =     Regroupement             */

/* ------------------------------- */

html,
#contact_rapide {
  background-color: #e1e1e1;
}

.table tr:hover,
#actualites,
#coordonnees,
#carte,
.argument:hover,
.argument:focus,
#pied_page {
  background-color: #f6f6f6;
}

#gamme,
#focus {
  background-color: #d31f31;
}

#gamme > ul,
.gamme,
#focus_actualites {
  background-color: #f1f1f1;
}

.gamme,
#focus_actualites {
  background: linear-gradient(135deg, rgba(255, 255, 255, 1) 0%, rgba(241, 241, 241, 1) 100%);
}

#actualites,
#coordonnees {
  padding-left: 40px;
  padding-right: 40px;
}

#slider .caption h2,
#selection .caption h3 {
  font-size: 1.5625em; /* 25px / 16px */
}

.table > caption,
.table th,
.table td,
.accordion-title,
.onglets,
.onglets a,
#en-tete,
#menu .btn:hover,
#menu .btn:focus,
#contenu_menu .menu_niv2,
#contenu_menu .menu_niv2 > li,
#fabrication_fr,
#focus_actualites li,
.blog,
#arguments,
#arguments li,
.produit,
#filtres_cat a,
#pagination a:hover,
#pagination a:focus,
#details_produit .pdf_titre > h3 {
  border: 1px solid #eee;
}

body,
#contenu_menu > li:last-child > .menu_niv2 a,
#selection .caption h2 {
  font-family: 'helvetica_neue_lt_std_light', 'helvetica neue', helvetica, arial, sans-serif;
}

h1,
.h1-like,
h2,
.h2-like,
h3,
.h3-like,
h4,
.h4-like,
h5,
.h5-like,
h6,
.h6-like,
.table th,
#contenu_menu .menu_niv2 > li > a,
#fabrication_fr > span,
.onglets,
#coordonnees dt,
#filtres_cat,
#produit .shop_attributes th {
  font-family: 'helvetica_neue_lt_std_medium', 'helvetica neue', helvetica, arial, sans-serif;
}

/* ------------------------------- */

/*  =     En-tête                  */

/* ------------------------------- */

#en-tete,
#conteneur_menu,
#contenu_menu .menu_niv2 {
  background-color: #fff;
}

#en-tete {
  border-style: none none solid;
}

#logo_en-tete,
#menu,
#conteneur_menu {
  padding: 10px 15px 15px;
}

#logo_en-tete,
#menu,
#commandes {
  vertical-align: middle !important;
}

/*  = --- Logo en-tête ----------- */

#logo_en-tete {
  font-size: 0;
}

#logo_en-tete::after {
  display: none;
}

#logo_en-tete > a:hover,
#logo_en-tete > a:focus {
  filter: alpha(opacity=50);
  opacity: 0.5;
}

/*  = --- Menu ------------------- */

#menu {
  font-family: 'helvetica_neue_lt_std_roman', 'helvetica neue', helvetica, arial, sans-serif;
  font-size: 0.875em; /* 14px / 16px */
}

#menu ul {
  padding-left: 0;
}

nav#menu a:hover,
nav#menu a:focus,
nav#menu a.actif {
  color: #d31f31 !important;
}

#menu a:focus {
  outline-color: #d31f31;
}

#menu .btn {
  background-color: transparent;
  border-color: transparent;
  line-height: 50px;
  padding: 0;
  width: 50px;
}

#menu a.gestion_menu {
  color: #333 !important;
  font-size: 30px;
}

#conteneur_menu {
  bottom: 0;
  overflow: auto;
  position: fixed;
  right: -100%;
  top: 0;
  transition: right 0.5s;
  z-index: 9999;
}

#conteneur_menu:target {
  right: 0;
}

#contenu_menu a {
  color: #999;
  line-height: 2.1428571428571428571428571428571; /* 30px / 14px */
  outline-style: none;
  padding: 0 10px;
}

#contenu_menu a:hover,
#contenu_menu a:focus,
#contenu_menu a.actif {
  background-color: #f6f6f6;
}

#contenu_menu > li > a,
#contenu_menu .menu_niv2 > li > a {
  color: #333;
  text-transform: uppercase;
}

#contenu_menu > li > a {
  line-height: 4.2857142857142857142857142857143; /* 60px / 14px */
}

#contenu_menu .menu_niv2 {
  border-style: hidden;
}

#contenu_menu .menu_niv2 > li {
  border-style: none none solid;
}

#contenu_menu .menu_niv2 > li > a {
  font-size: 1.2857142857142857142857142857143em; /* 18px / 14px */
  line-height: 2.5; /* 45px / 18px */
}

#contenu_menu .menu_niv4 {
  padding-left: 1.5em;
}

/* Langues */

#contenu_menu > li:last-child > .menu_niv2 a {
  font-size: 1em;
  line-height: 2.1428571428571428571428571428571; /* 30px / 14px */
  text-transform: none;
}

html[lang='fr-FR'] #contenu_menu > li:last-child > .menu_niv2 a:not([href*='/en-gb/']),
html[lang='fr-CH'] #contenu_menu > li:last-child > .menu_niv2 a:not([href*='/de-ch/']),
html[lang='fr-BE'] #contenu_menu > li:last-child > .menu_niv2 a:not([href*='-be/']),
html[lang='en-GB'] #contenu_menu > li:last-child > .menu_niv2 a:not([href*='/fr-fr/']),
html[lang='de-BE'] #contenu_menu > li:last-child > .menu_niv2 a:not([href*='-be/']),
html[lang='de-CH'] #contenu_menu > li:last-child > .menu_niv2 a:not([href*='/fr-ch/']),
html[lang='nl-BE'] #contenu_menu > li:last-child > .menu_niv2 a:not([href*='-be/']) {
  display: none;
}

/* Commandes */

#commandes,
#commandes a {
  color: #666 !important;
}

#commandes {
  font-size: 0.85714285714285714285714285714286em; /* 12px / 14px */
  margin-top: 0;
}

#commandes > li {
  vertical-align: middle;
}

#commandes > li + li {
  margin-left: 10px;
}

#commandes .btn {
  font-size: 20px;
}

/* ------------------------------- */

/*  =     Recherche                */

/* ------------------------------- */

#recherche {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.8);
  filter: alpha(opacity=0);
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: opacity 0.5s;
  visibility: hidden;
  z-index: 9999;
}

#recherche:target {
  filter: alpha(opacity=100);
  opacity: 1;
  visibility: visible;
}

#recherche > form {
  padding: 20px;
  vertical-align: middle !important;
}

#fermer_recherche {
  position: absolute;
  right: 20px;
  top: 20px;
}

#woocommerce-product-search-field-0 {
  background-color: transparent;
  border-left-color: transparent;
  border-left-width: 1px;
  border-right-color: transparent;
  border-top-color: transparent;
}

/* ------------------------------- */

/*  =     Contenu principal        */

/* ------------------------------- */

/*  = --- Slider ----------------- */

#slider {
  color: #fff;
  position: relative;
  text-transform: uppercase;
}

#slider .caption h2 {
  color: inherit;
}

#slider .caption .btn {
  border-color: #fff;
  color: #fff !important;
}

#slider .caption .btn:hover,
#slider .caption .btn:focus {
  background-color: #d31f31 !important;
}

#slider .caption .btn:hover > i,
#slider .caption .btn:focus > i {
  color: inherit;
}

/* Slogan */

#slogan {
  bottom: 30px;
  display: none;
  position: absolute;
}

#slogan > img {
  filter: brightness(0) invert(1);
}

/* Fabrication FR */

#fabrication_fr {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  bottom: 60px;
  cursor: help;
  font-size: 0.6875em; /* 11px / 16px */
  line-height: 1;
  padding: 5px;
  position: absolute;
  right: 0;
}

#fabrication_fr > img {
  margin-bottom: 0;
}

#fabrication_fr > span {
  font-size: 1.0909090909090909090909090909091em; /* 12px / 11px */
}

/*  = --- Gamme ------------------ */

#gamme {
  overflow: hidden;
}

#gamme > header > h2,
.gamme dl {
  margin: 15px;
}

#gamme > header > h2 {
  color: #fff;
}

#gamme > header > h2::after {
  background-color: #333;
}

#gamme > ul {
  margin-top: 0;
  padding-left: 0;
}

.gamme > a {
  color: inherit;
  overflow: hidden;
  padding-top: 71.428571428571428571428571428571%; /* 300px * 100 / 420px */
}

.gamme > a:hover,
.gamme > a:focus {
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.gamme dl {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
}

.gamme dt {
  margin-bottom: 1em;
}

.gamme dt.h2-like::after {
  display: none;
}

.gamme dd {
  margin-left: 0;
}

/*  = --- Sélection -------------- */

#selection .caption-wrap {
  background-color: #fff;
  color: #666;
  position: static;
}

#selection .caption .btn {
  margin-top: 2em;
}

#selection .flex-control-nav {
  bottom: auto;
  top: 30px;
}

/*  = --- Focus ------------------ */

#focus {
  background-image: url('../img/focus.png');
  background-position: -50px -25px;
  background-repeat: no-repeat;
}

#focus_documentations,
#focus_actualites {
  padding: 50px;
}

/* Focus documentations */

#focus_documentations {
  color: #fff;
}

#focus_documentations .h1-like,
#focus_documentations .h3-like {
  color: inherit;
}

#focus_documentations .h1-like::after {
  background-color: #333;
}

#focus_documentations .h3-like {
  font-family: inherit;
}

#focus_documentations .btn {
  border-color: #fff;
}

/* Focus actualités */

#focus_actualites > div {
  height: 375px;
  overflow-y: auto;
}

#focus_actualites ul {
  padding-left: 0;
}

#focus_actualites li {
  border-style: none none solid;
}

/*  = --- Introduction ----------- */

#introduction {
  padding-top: 50px;
}

/* Fil d'ariane */

#fil_ariane,
#fil_ariane a {
  color: #bcbcbc;
}

#fil_ariane {
  font-size: 0.875em; /* 14px / 16px */
  padding-left: 0;
}

#fil_ariane a:hover,
#fil_ariane a:focus {
  color: #333;
}

#fil_ariane a:focus {
  outline-color: #333;
}

#fil_ariane > li + li::before {
  content: '>';
  margin: 0 0.5em; /* 7px / 14px */
}

/*  = --- Actualités ------------- */

/*  = --- Contenu ---------------- */

.contenu {
  padding: 20px 10px 100px;
}

.contenu a {
  color: #d31f31;
}

.contenu a:hover,
.contenu a:focus {
  background-color: #d31f31;
  color: #fff;
}

/* Accueil */

/* Société */

/* Documentations */

#documentations > ul {
  padding-left: 0;
}

/* Blog */

#blog > ul {
  padding-left: 0;
}

.blog {
  border-style: none none solid;
  padding: 25px;
}

.blog > a {
  margin: 0 0 25px;
}

.blog img {
  max-width: 300px;
}

.blog header {
  display: flex;
  flex-direction: column;
}

.blog h2 {
  margin-top: 0.5em; /* 15px / 30px */
  order: 1;
}

.blog .categories,
.blog .date {
  font-size: 0.8125em; /* 13px / 16px */
}

.blog .categories {
  padding-left: 0;
  text-transform: uppercase;
}

.blog .categories > li + li::before {
  content: '/ ';
}

.blog > div > p {
  font-size: 1.125em; /* 18px / 16px */
  margin-bottom: 1em;
}

/* Contact */

#coordonnees > dl {
  color: #333;
  font-size: 1.125em; /* 18px / 16px */
  max-width: 550px;
}

#coordonnees dd {
  margin-left: 0;
}

#contact .frm_description > p {
  text-align: center;
}

#contact input,
#contact textarea {
  color: #000;
}

#carte {
  background-image: url('../img/carte.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#carte > iframe {
  display: block;
  width: 100%;
}

/* Erreur */

#erreur #searchform label {
  margin-top: 0;
}

/* WooCommerce */

#woocommerce .button {
  background-color: #fff !important;
  border: 1px solid #ccc !important;
  color: #333 !important;
}

#woocommerce .button:hover,
#woocommerce .button:focus {
  border-color: #d31f31 !important;
  color: #d31f31 !important;
}

#woocommerce .shop_table {
  table-layout: auto;
}

#woocommerce .shop_table th,
#woocommerce .shop_table td {
  border-style: hidden;
}

#woocommerce .shop_table td,
#woocommerce .cart_totals th {
  border-top-style: solid;
}

#woocommerce .shop_table th {
  background-color: #eee;
}

#woocommerce .shop_table .quantity > .qty {
  border-left-width: 1px;
  padding-right: 0;
  text-align: left;
  width: 5em;
}

#woocommerce .coupon > input {
  width: 100%;
}

/* ------------------------------- */

/*  =     Arguments                */

/* ------------------------------- */

#arguments {
  border-style: hidden;
}

#arguments > ul {
  margin-left: auto;
}

#arguments li {
  border-style: solid none;
  padding-left: 0;
}

.argument {
  padding: 0 10px 50px;
}

.argument::before {
  background: url('../img/arguments.png') no-repeat;
  content: '';
  display: block;
  height: 110px;
  transition: transform 0.5s;
}

.argument.performance::before {
  background-position: center top;
}

.argument.recherche::before {
  background-position: center -110px;
}

.argument.environnement::before {
  background-position: center -220px;
}

.argument.assistance::before {
  background-position: center -330px;
}

.argument.logistique::before {
  background-position: center -440px;
}

.argument:hover::before,
.argument:focus::before {
  transform: rotate(10deg);
}

.argument > p {
  color: #666;
}

/* ------------------------------- */

/*  =     Pied de page             */

/* ------------------------------- */

#pied_page,
#pied_page a {
  color: #999;
}

#pied_page {
  text-transform: uppercase;
}

#pied_page a:hover,
#pied_page a:focus {
  color: #333;
}

#pied_page a:focus {
  outline-color: #333;
}

#pied_page > div {
  padding: 60px 15px 30px;
}

/*  = --- Marque ----------------- */

#marque {
  color: #333;
  font-size: 12px;
  font-size: 1.2rem;
}

/*  = --- Menu secondaire -------- */

#menu_secondaire {
  font-size: 0;
  margin-top: 50px;
}

#menu_secondaire ul {
  line-height: 2.1875; /* 35px / 16px */
  margin-top: 0;
}

#menu_secondaire ul:first-child {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.7857142857142857142857142857143; /* 25px / 14px */
}

#menu_secondaire a {
  outline-style: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/*  = --- Présentation rapide ---- */

#presentation_rapide {
  text-transform: none;
}

/*  = --- Contact rapide --------- */

#contact_rapide .grid3 > div {
  font-size: 14px;
  font-size: 1.4rem;
}

#contact_rapide .grid3 > div + div {
  margin-top: 30px;
}

#contact_rapide dd {
  margin-left: 0;
}

/* ------------------------------- */

/*  =     Liste produits           */

/* ------------------------------- */

.term-description {
  margin-bottom: 45px;
}

#produits {
  padding: 10px 20px;
}

.produit {
  border-style: none none solid;
  margin: 10px auto;
  padding: 25px;
  transition: box-shadow 0.5s;
}

.produit:hover {
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
}

.produit > a {
  background-color: transparent !important;
  height: 250px;
  margin-bottom: 20px;
  overflow: hidden;
}

.produit img {
  max-height: 100%;
  transition: transform 0.5s;
  width: auto;
}

.produit:hover img,
.produit > a:focus > img {
  transform: scale(1.25);
}

.produit h2,
.produit > p {
  overflow: hidden;
  word-break: break-word;
}

.produit:hover h2 > a,
.produit h2 > a:focus {
  color: #d31f31 !important;
}

/*  = --- Tri -------------------- */

#tri {
  margin-bottom: 20px;
}

#tri .orderby {
  border-left-width: 1px;
  height: 2.1875em; /* 35px / 16px */
  line-height: 2.1875;
}

/*  = --- Filtres cat ------------ */

#filtres_cat ul {
  line-height: 3.125; /* 50px / 16px */
  list-style-type: none;
  padding-left: 0;
  text-transform: uppercase;
}

#filtres_cat a {
  border-style: none none solid;
  color: #333;
  display: block;
  overflow: hidden;
  padding: 0 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#filtres_cat a:hover,
#filtres_cat a:focus {
  background-color: transparent;
  border-bottom-color: #333;
}

#filtres_cat .current-cat > a {
  color: #d31f31;
}

#filtres_cat .children {
  display: none;
}

/*  = --- Filtres sous-cat ------- */

#filtres_sous-cat {
  margin-bottom: 30px;
}

/*  = --- Pagination ------------- */

#pagination {
  color: #fff;
  margin-top: 50px;
}

#pagination > ul {
  list-style-type: none;
  padding-left: 0;
}

#pagination li {
  background-color: #d31f31;
  display: inline-block;
  line-height: 3.125;
  width: 3.125em; /* 50px / 16px */
}

#pagination a {
  background-color: #fff;
  border: 1px solid transparent;
  color: #666;
  display: block;
}

/* ------------------------------- */

/*  =     Fiche produit            */

/* ------------------------------- */



#documentations_produit .btn,
#navigation_produit,
#navigation_produit .btn {
  border-width: 2px !important;
}

#documentations_produit .btn,
#navigation_produit .btn {
  color: inherit !important;
}

#produit > .related,
#details_produit {
  border-top: 1px solid #333;
  margin-top: 40px;
  padding-top: 40px;
}

#produit table,
#produit th,
#produit td {
  border-style: hidden;
}

#produit .flex-control-nav {
  list-style-type: none;
  margin-top: 10px;
  padding-left: 0;
}

#produit .flex-control-nav > li {
  display: inline-block;
  padding: 10px;
  width: 20%;
}

#produit .flex-control-nav img {
  cursor: pointer;
}

#produit > .summary {
  margin-bottom: 80px;
}

#produit > .summary > table,
#produit > .summary > table td {
  border-style: hidden;
}

#produit > .summary > table td {
  padding: 1em 0;
}

#produit > .summary h3 {
  font-size: 1em;
}

#produit .shop_attributes {
  font-size: 0.875em; /* 14px / 16px */
}

#produit .shop_attributes th {
  font-weight: normal;
  text-transform: uppercase;
}

#produit .shop_attributes td {
  margin-top: 0;
}

/*  = --- Date MAJ produit ------- */

#date_maj_produit {
  font-size: 0.75em; /* 12px / 16px */
}

#date_maj_produit > dd {
  margin-left: 0;
}

#date_maj_produit > dd::before {
  content: ':';
}

/*  = --- Documentations produit - */

#documentations_produit {
  font-size: 0.875em; /* 14px / 16px */
  padding-left: 0;
}


.htmlToPdf {
  position: relative;
}

// .htmlToPdf .wps-pgfw-pdf-generate-icon__wrapper-frontend {
//   opacity: 0;
//   position: absolute;
//   text-align: center;
//   width: 100%;
// }

// .htmlToPdf .wps-pgfw-pdf-generate-icon__wrapper-frontend a.pgfw-single-pdf-download-button {
//   display: block;
//   width: 100%;
// }


#documentations_produit > li + li {
  margin-top: 1em;
}

#documentations_produit i {
  color: #d31f31;
}

/*  = --- Détails produit -------- */

#details_produit > header + table td:not(.pdf_titre):first-child {
  width: 15%;
}

#details_produit .pdf_titre > h3 {
  border-style: hidden hidden solid;
  padding-bottom: 1em;
}

#details_produit .pdf_titre > h3::before {
  content: '\f054';
  font-family: fontawesome;
  margin-right: 1em;
  vertical-align: middle;
}

/*  = --- Navigation produit ----- */

#navigation_produit {
  border-color: #ccc;
  border-style: hidden;
  font-size: 0.75em; /* 12px / 16px */
  margin-top: 100px;
}

#navigation_produit > ul {
  padding-left: 0;
}

#navigation_produit li + li {
  margin-top: 0.5em;
}

#navigation_produit .btn:hover,
#navigation_produit .btn:focus,
#navigation_produit i {
  color: #333 !important;
}

#navigation_produit .btn:hover,
#navigation_produit .btn:focus {
  border-color: #333;
}

/* ------------------------------- */

/*  =     Media Queries            */

/* ------------------------------- */

/*  = --- Tiny ------------------- */

@media (max-width: 480px) {
  .tiny-icone_produit {
    padding-left: 100px;
  }

  .tiny-icone_produit::before {
    display: block;
  }

  #contenu_menu .tiny-icone_produit > a,
  #documentations .tiny-icone_produit caption {
    margin-left: -100px;
  }

  #contenu_menu .tiny-icone_produit::before {
    top: 3.2142857142857142857142857142857em; /* 45px / 14px */
  }

  #documentations .tiny-icone_produit::before {
    margin-top: 3px; /* 2px + 1px */
    top: 3.75em; /* 30px / 16px * 2 */
  }
}

/*  = --- Small ------------------ */

@media (min-width: 481px) {
  .small-icone_produit {
    padding-left: 100px;
  }

  .small-icone_produit::before {
    display: block;
  }

  #contenu_menu .small-icone_produit > a,
  #documentations .small-icone_produit caption {
    margin-left: -100px;
  }

  #contenu_menu .small-icone_produit::before {
    top: 3.2142857142857142857142857142857em;
  }

  #documentations .small-icone_produit::before {
    margin-top: 3px;
    top: 3.75em;
  }

  #recherche {
    font-size: 2.25em; /* 36px / 16px */
  }

  #coordonnees > dl {
    text-align: right;
  }

  #coordonnees .left {
    text-align: left;
  }

  #arguments li {
    border-style: solid;
  }

  .argument {
    height: 200px;
  }

  .produit h2 {
    height: 2em; /* 1em * 2 */
  }

  .produit > p {
    height: 4.5em; /* 1.5em * 3 */
  }
}

/*  = --- Medium ----------------- */

@media (min-width: 769px) {
  .medium-icone_produit {
    padding-left: 100px;
  }

  .medium-icone_produit::before {
    display: block;
  }

  #contenu_menu .medium-icone_produit > a,
  #documentations .medium-icone_produit caption {
    margin-left: -100px;
  }

  #contenu_menu .medium-icone_produit::before {
    top: 3.2142857142857142857142857142857em;
  }

  #documentations .medium-icone_produit::before {
    margin-top: 3px;
    top: 3.75em;
  }

  .onglets {
    border-bottom-style: solid !important;
  }

  .onglets li {
    display: inline-block;
  }

  .onglets li + li {
    margin-top: 0;
  }

  .onglets a {
    border-bottom-style: hidden;
  }

  #contenu_menu .menu_niv2 > li {
    border-style: none none none solid;
    padding-bottom: 40px;
  }

  #contenu_menu > li:last-child > .menu_niv2 > li {
    padding-bottom: 0;
  }

  #focus {
    background-position: left -50px bottom -50px;
  }

  .blog {
    text-align: left;
  }

  .blog > a {
    margin: 0 25px;
  }

  .blog h2 {
    text-align: inherit;
  }

  .blog h2::after {
    margin-left: 0;
  }

  #blog > ul > li:nth-child(odd) > .blog > a {
    float: left;
    margin-left: 0;
  }

  #blog > ul > li:nth-child(even) > .blog > a {
    float: right;
    margin-right: 0;
  }

  #arguments {
    border-style: solid none;
  }

  #arguments li {
    border-style: hidden;
  }

  #arguments li + li {
    border-left-style: solid;
  }

  div#contact_rapide {
    padding-bottom: 20px;
    padding-top: 20px;
  }

  #contact_rapide .grid3 > div + div {
    margin-top: 0;
  }

  #tri > .woocommerce-result-count,
  #tri > .woocommerce-ordering {
    vertical-align: middle;
  }

  #tri .orderby {
    margin-left: 10px;
  }

  #tri ~ *:not(#filtres_sous-cat) {
    clear: right;
  }

  #filtres_cat li {
    float: left;
    width: 50%;
  }

  #navigation_produit {
    border-top-style: solid;
  }

  #navigation_produit li + li {
    margin-left: 0.5em;
    margin-top: 0;
  }

  #navigation_produit .btn {
    border-top-style: hidden;
    min-width: 230px;
  }
}

/*  = --- Large ------------------ */

@media (min-width: 1025px) {
  .large-icone_produit {
    padding-left: 100px;
  }

  .large-icone_produit::before {
    display: block;
  }

  #contenu_menu .large-icone_produit > a,
  #documentations .large-icone_produit caption {
    margin-left: -100px;
  }

  #contenu_menu .large-icone_produit::before {
    top: 3.2142857142857142857142857142857em;
  }

  #documentations .large-icone_produit::before {
    margin-top: 3px;
    top: 3.75em;
  }

  .onglets li + li {
    margin-left: 15px;
  }

  .onglets a {
    padding: 20px;
  }

  // .htmlToPdf .wps-pgfw-pdf-generate-icon__wrapper-frontend {
  //   opacity: 0;
  //   position: absolute;
  //   text-align: center;
  //   width: 75%;
  // }

  .slider .caption > div {
    padding-left: 40px;
    padding-right: 40px;
  }

  #en-tete {
    min-height: 5.625em; /* 90px / 16px */
    position: fixed;
    top: 0;
    z-index: 9999;
  }

  #contenu_menu > li > a {
    font-size: 0.85714285714285714285714285714286em; /* 12px / 14px */
    line-height: 7.5; /* 90px / 12px */
  }

  #contenu_principal {
    margin-top: 5.625em;
  }

  #en-tete.scroll {
    font-size: 0.875em; /* 14px / 16px */
    min-height: 0;
  }

  #en-tete > div,
  #menu {
    table-layout: auto;
  }

  #logo_en-tete,
  #menu,
  #conteneur_menu {
    padding-bottom: 0;
    padding-top: 0;
  }

  #conteneur_menu {
    background-color: transparent;
    overflow: visible;
    padding: 0;
    position: static;
    width: auto;
  }

  #contenu_menu {
    margin-top: 0;
    text-align: right;
  }

  #contenu_menu > li:hover > a {
    color: #d31f31;
  }

  #contenu_menu > li > a:hover,
  #contenu_menu > li > a:focus,
  #contenu_menu > li > a.actif {
    background-color: transparent;
  }

  #contenu_menu .menu_niv2 {
    border-style: solid none;
    display: flex;
    filter: alpha(opacity=0);
    flex-flow: column wrap;
    height: 800px;
    left: 0;
    margin-top: -20px;
    opacity: 0;
    position: absolute;
    right: 0;
    transition: margin-top 0.5s, opacity 0.5s;
    visibility: hidden;
    width: 100%;
    z-index: 9999;
  }

  #contenu_menu > li:hover > ul,
  #contenu_menu > li > a:focus + ul {
    filter: alpha(opacity=100);
    margin-top: 0;
    opacity: 1;
    visibility: visible;
  }

  #contenu_menu > li:last-child {
    position: relative;
  }

  #contenu_menu > li:last-child > .menu_niv2 {
    border-style: solid;
    height: auto;
    left: auto;
    min-width: 150px;
  }

  #contenu_menu > li:last-child > .menu_niv2 > li {
    border-left-style: hidden;
    width: auto !important;
  }

  #recherche {
    font-size: 3.375em; /* 54px / 16px */
  }

  #selection .caption-wrap {
    background-color: transparent;
    position: absolute;
  }

  #selection .caption {
    text-align: left;
    width: 50%;
  }

  #selection .caption .h1-like {
    text-align: inherit;
  }

  #selection .caption .h1-like::after {
    margin-left: 0;
  }

  #selection .flex-control-nav {
    bottom: 30px;
    top: auto;
  }

  #arguments li {
    width: 20%;
  }

  #menu_secondaire {
    margin-top: 0;
  }

  #menu_secondaire ul {
    text-align: right;
  }

  #contact_rapide .grid3 > div:first-child {
    text-align: left;
  }

  #contact_rapide .grid3 > div:last-child {
    text-align: right;
  }

  #contact_rapide dt,
  #contact_rapide dd {
    display: inline;
  }

  #contact_rapide dd::before {
    content: ' - ';
  }

  #contact_rapide .grid3 > div:nth-child(2) {
    width: 50% !important;
  }

  #filtres_cat {
    width: 340px;
  }

  #filtres_cat li {
    float: none;
    width: auto;
  }
}

/*  = --- 1360px ----------------- */

@media (min-width: 1360px) {
  .xlarge-icone_produit {
    padding-left: 100px;
  }

  .xlarge-icone_produit::before {
    display: block;
  }

  #contenu_menu .xlarge-icone_produit > a,
  #documentations .xlarge-icone_produit caption {
    margin-left: -100px;
  }

  #contenu_menu .xlarge-icone_produit::before {
    top: 3.2142857142857142857142857142857em;
  }

  #documentations .xlarge-icone_produit::before {
    margin-top: 3px;
    top: 3.75em;
  }

  .slider .caption > div {
    padding-left: 80px;
    padding-right: 80px;
  }

  #slider .caption h2,
  #selection .caption h3 {
    font-size: 2.25em; /* 36px / 16px */
  }

  #contenu_menu > li > a {
    font-size: 1em;
    line-height: 6.4285714285714285714285714285714; /* 90px / 14px */
    padding: 0 1.4285714285714285714285714285714em; /* 20px / 14px */
  }

  #contenu_menu .menu_niv2 {
    padding: 50px 25px;
  }

  #contenu_menu > li:last-child > .menu_niv2 {
    padding: 0;
  }

  #gamme {
    overflow: visible;
  }

  #gamme > header > h2,
  .gamme dl {
    margin-left: 30px;
    margin-top: 30px;
  }

  #gamme > header {
    float: left;
    width: 25%;
  }

  #gamme > header > h2 {
    text-align: left;
  }

  #gamme > header > h2::after {
    margin-left: 0;
  }

  #gamme > ul {
    background-color: transparent;
  }

  #focus_documentations,
  #focus_actualites {
    padding-top: 100px;
  }

  .contenu {
    padding-left: 0;
    padding-right: 0;
  }

  .blog img {
    max-width: 100%;
  }
}

/*  = --- 1680px ----------------- */

@media (min-width: 1680px) {
  .xxlarge-icone_produit {
    padding-left: 100px;
  }

  .xxlarge-icone_produit::before {
    display: block;
  }

  #contenu_menu .xxlarge-icone_produit > a,
  #documentations .xxlarge-icone_produit caption {
    margin-left: -100px;
  }

  #contenu_menu .xxlarge-icone_produit::before {
    top: 3.2142857142857142857142857142857em;
  }

  #documentations .xxlarge-icone_produit::before {
    margin-top: 3px;
    top: 3.75em;
  }

  .slider .caption > div {
    padding-left: 160px;
    padding-right: 160px;
  }

  #logo_en-tete {
    padding-left: 0;
  }

  #menu {
    padding-right: 0;
  }

  #gamme > header > h2,
  .gamme dl {
    margin-left: 60px;
    margin-top: 75px;
  }

  .gamme > a {
    padding-top: 300px;
  }

  #pied_page > div {
    padding-left: 0;
    padding-right: 0;
  }
}
